
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








$parallax-amount: 10%;

.wrapper {
  position: relative;
  overflow: hidden;
}

.visual {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: none;
  height: 100%;
  width: 100%;
  object-fit: cover;

  @include mq($from: m) {
    @include parallax-translate-x(-$parallax-amount);

    right: -$parallax-amount * 2;
    left: -$parallax-amount * 2;
    width: 100 + $parallax-amount * 4;
  }
}
