
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.form-feedback {
  margin-top: 1em;
  font-size: 1.4rem;
}

.newscenter {
  margin-top: 30px;
}
