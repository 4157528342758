
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.horizontal-scroller {
  @include mq($from: m) {
    position: fixed;
    display: flex;
    flex-wrap: nowrap;
    user-select: none;
    user-drag: none;
    cursor: grab;
  }
}

.vertical-placeholder {
  display: none;

  @include mq($from: m) {
    display: block;
  }
}
