
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































































.slideshow {
  padding: 84px 140px;
  // height: 100%; // Title will stay at the same position, regardless of slide's content height

  @include mq($until: m) {
    padding: 84px 100px;
  }

  @include mq($until: s) {
    padding: 100px 32px;
  }

  &__title {
    font-size: 38px;
    line-height: 1;
    margin-bottom: 41px;
  }

  &__grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 208px minmax(auto, 368px);
    grid-template-rows: 210px 1fr;
    gap: 30px 50px;
    grid-template-areas:
      'visuals text'
      'controls text';

    @include mq($until: s) {
      grid-template-columns: 232px auto;
      grid-template-rows: 210px auto;
      gap: 30px 30px;
      grid-template-areas:
        'visuals controls'
        'text text';
    }
  }

  &__visuals,
  &__slides {
    position: relative;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__visuals {
    grid-area: visuals;
    position: relative;
  }

  &__slides {
    grid-area: text;
  }

  //   &__visual,
  //   &__slide {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //   }

  &__visual {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slide {
    &__content {
      font-family: 'Futura Book';
      font-size: 14px;
      line-height: 2;
      margin-bottom: 36px;
      letter-spacing: 0.6px;

      @include mq($from: s) {
        margin-top: 80px;
      }
    }

    &__author {
      font-family: 'Futura Bold';
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.6px;
    }

    &__author-job {
      font-family: 'Futura Book';
      font-size: 13px;
      line-height: 18px;
      color: #7d8093;
      letter-spacing: 0.6px;
    }
  }

  &__controls {
    grid-area: controls;
    display: flex;
    flex-direction: column;

    @include mq($from: s) {
      flex-direction: row;
    }
  }

  &__button {
    width: 54px;
    height: 54px;
    padding: 0;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;

    &:first-of-type {
      margin-bottom: 12px;
    }

    @include mq($from: s) {
      &:first-of-type {
        margin-right: 22px;
        margin-bottom: 0;
      }
    }
  }
}
